<template>
	<div id="aboutUs" :class="[onlinestate=='ipad'?'ipad_class':onlinestate == 'phone'?'phone_class':'pc_class']">
		<div class='heard'>
			<div id='wrap_hand' class='wrap_hand wrap_hand_animation'>
				<div class='heard_left myun_down' @click="backClick()" style="cursor: pointer;"><img src="@/image/onhand/logo.png" alt=""></div>
				<div class='heard_left mydown'><img src="@/image/onhand/logo_pc.png" alt=""></div>
				<div class='heard_right mydown'><img src="@/image/onhand/icon.png" alt=""></div>
				<div class='heard_right myun_down'>
					<section class="mod model-1">
						<div class="menu" @click="menuPage()" :class="menuFlag ?'menu menu_hover':'menu'">
							<div class="bar"></div>
							<div class="bar"></div>
							<div class="bar"> </div>
						</div>
					</section>
				</div>
			</div>
        </div>
        <div>
            <img :src="params.banner.banner_url_web" alt="" style="width:100%;" v-if="onlinestate == 'pc'">
            <img :src="params.banner.banner_url_app" alt="" style="width:100%;min-height: 167px;" v-else>
            <div class="text_aboutUs">
				<div v-for="(item,index) in params.aboutUs" :key="index">
					<div class="title_text " :style="{marginTop: index>0 ?'20px':'0px'}" v-if="item.page_title">
						<div></div>
						{{item.page_title}}
					</div>
					<div style="margin-top:10px;padding-left: 1%;" v-if="item.page_content">
						{{  item.page_content }}
					</div>
				</div>
                <!-- <div class="title_text jyjh_id" style="margin-top:20px">
                    <div></div>
                    就业机会
                </div>
                <div style="margin-top:10px;padding-left: 1%;">
                    大连铧亚软件科技开发有限公司的专业团队致力提供卓越及多元化的技术服务。人才是本公司极其宝贵的资产，与增强我们的整体核心竞争力及不断扩展的业务上发挥着至关重要的作用。我们为有志投身软件开发的优秀人才提供最佳的培训、具竞争力的薪酬待遇及良好的职业发展机会。现在，诚邀你加我们的专业团队。
                </div>
                <div class="title_text" style="margin-top:20px">
                    <div></div>
                    职位空缺
                </div> -->
                <div v-if="onlinestate == 'pc'">
                    <div style="display:flex;margin-top: 10px;">
                        <div style="width:25%;display: flex;align-items: center;flex-shrink: 0;padding-left: 1%;cursor: pointer;" @click="info_page(jobVacancy[0].id)">
                            <img src="../../image/icon/ui.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[0].job_name }}</div>
                        </div>
                        <div style="width:25%;display: flex;align-items: center;flex-shrink: 0;cursor: pointer;" @click="info_page(jobVacancy[1].id)">
                            <img src="../../image/icon/ios.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[1].job_name }}</div>
                        </div>
                        <div style="width:25%;display: flex;align-items: center;flex-shrink: 0;cursor: pointer;" @click="info_page(jobVacancy[2].id)">
                            <img src="../../image/icon/c++.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[2].job_name }}</div>
                        </div>
                        <div style="width:25%;display: flex;align-items: center;flex-shrink: 0;cursor: pointer;" @click="info_page(jobVacancy[3].id)">
                            <img src="../../image/icon/az.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[3].job_name }}</div>
                        </div>
                    </div>
                    <div style="display:flex;margin-top: 10px;">
                        <div style="width:25%;display: flex;align-items: center;flex-shrink: 0;padding-left: 1%;cursor: pointer;" @click="info_page(jobVacancy[4].id)">
                            <img src="../../image/icon/web.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[4].job_name }}</div>
                        </div>
                        <div style="width:25%;display: flex;align-items: center;flex-shrink: 0;cursor: pointer;" @click="info_page(jobVacancy[5].id)">
                            <img src="../../image/icon/java.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[5].job_name }}</div>
                        </div>
                       
                    </div>
                </div>
                <div v-else-if="onlinestate == 'ipad'">
                    <div style="display:flex;margin-top: 10px;">
                        <div style="width:100%;display: flex;align-items: center;cursor: pointer;" @click="info_page(jobVacancy[0].id)">
                            <img src="../../image/icon/ui.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[0].job_name }}</div>
                        </div>
                        <div style="width:100%;display: flex;align-items: center;cursor: pointer;" @click="info_page(jobVacancy[1].id)">
                            <img src="../../image/icon/ios.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[1].job_name }}</div>
                        </div>
						<div style="width:100%;display: flex;align-items: center;cursor: pointer;" @click="info_page(jobVacancy[2].id)">
                            <img src="../../image/icon/c++.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[2].job_name }}</div>
                        </div>
                    </div>
                    <div style="display:flex;margin-top: 10px;">
						<div style="width:100%;display: flex;align-items: center;cursor: pointer;" @click="info_page(jobVacancy[3].id)">
                            <img src="../../image/icon/az.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[3].job_name }}</div>
                        </div>
                        <div style="width:100%;display: flex;align-items: center;padding-left: 1%;cursor: pointer;" @click="info_page(jobVacancy[4].id)">
                            <img src="../../image/icon/web.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[4].job_name }}</div>
                        </div>
                        <div style="width:100%;display: flex;align-items: center;cursor: pointer;" @click="info_page(jobVacancy[5].id)">
                            <img src="../../image/icon/java.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[5].job_name }}</div>
                        </div>
                    </div>
					<!-- <div style="height: 55px;"></div> -->
                </div>
                <div v-else>
                    <div style="display:flex;margin-top: 10px;">
                        <div style="width:100%;display: flex;align-items: center;cursor: pointer;" @click="info_page(jobVacancy[0].id)">
                            <img src="../../image/icon/ui.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[0].job_name }}</div>
                        </div>
                        <div style="width:100%;display: flex;align-items: center;cursor: pointer;" @click="info_page(jobVacancy[1].id)">
                            <img src="../../image/icon/ios.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[1].job_name }}</div>
                        </div>
                    </div>
                    <div style="display:flex;margin-top: 10px;">
						<div style="width:100%;display: flex;align-items: center;cursor: pointer;" @click="info_page(jobVacancy[2].id)">
                            <img src="../../image/icon/c++.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[2].job_name }}</div>
                        </div>
						<div style="width:100%;display: flex;align-items: center;cursor: pointer;" @click="info_page(jobVacancy[3].id)">
                            <img src="../../image/icon/az.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[3].job_name }}</div>
                        </div>
                    </div>
                    <div style="display:flex;margin-top: 10px;">
                        <div style="width:100%;display: flex;align-items: center;padding-left: 1%;cursor: pointer;" @click="info_page(jobVacancy[4].id)">
                            <img src="../../image/icon/web.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[4].job_name }}</div>
                        </div>
                        <div style="width:100%;display: flex;align-items: center;cursor: pointer;" @click="info_page(jobVacancy[5].id)">
                            <img src="../../image/icon/java.png" alt="" style="margin-right: 5px;width: 20px;">
                            <div>{{ jobVacancy[5].job_name }}</div>
                        </div>
                    </div>
					<!-- <div style="height: 55px;"></div> -->
                </div>
            </div>
            <div style="background-color: #0d626b;position: fixed;width: 100%;bottom: 0;">
                <div class='home_bottoom' v-if="onlinestate == 'pc'">
                    <div style="font-size: 13px;color: #fff;">
                        © 2022大连铧亚软件科技开发有限公司版权所有
                    </div>
                    <div style="font-size: 13px;color: #fff;">
                        辽ICP备2021010794号-1
                    </div>
                </div>
                <div class='home_bottoom_phone' v-else>
                    <div style="font-size: 13px;color: #fff;padding: 5px 0;">
                        © 2022大连铧亚软件科技开发有限公司版权所有
                    </div>
                    <div style="font-size: 13px;color: #fff;padding: 5px 0;">
                        辽ICP备2021010794号-1
                    </div>
                </div>
            </div>
        </div>
		<div style="height: 20px;"  v-if="onlinestate == 'phone'"></div>
        <div class="menu_div" v-if="menuFlag" @click="Move()">
			<div style="text-align: center;color: #ffffff;" class="menu_class">
				<div class="back_hover" style="cursor: pointer;" @click="skipClick(1)">主页</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" :style="{color:colorFlag == 2 ? '#40bfbf':''}" @click="skipClick(2)">关于我们</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" @click="skipClick(3)">服务范畴</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" @click="skipClick(4)">作品案例</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" :style="{color:colorFlag == 5 ? '#40bfbf':''}" @click="skipClick(5)">就业机会</div>
				<div class="back_hover" style="margin-top:10px;cursor: pointer;" @click="skipClick(6)">联络我们</div>
			</div>
		</div>
		<cookie></cookie>
	</div>
</template>

<script>
	import AOS from "aos";
	import cookie from '../../cookies/cookie.vue'
	export default {
		components: {
			cookie
		},
		name: 'aboutUs',
		data() {
			return {
				onlinestate:sessionStorage.onlinestate,
				menuFlag:false,
				colorFlag:null,
				params: {},
				jobVacancy:[],
			}
		},
		mounted() {
			this.getdate()
			this.colorFlag = this.$route.query.id
			// if (this.colorFlag == 5) {
			// 	// 获取页面最底部元素
			// 	const footerElement = document.querySelector('.jyjh_id');
			// 	// 滚动到底部的位置
			// 	const scrollOptions = {
			// 		top: footerElement.offsetTop,
			// 		behavior: 'smooth' // 使用平滑滚动效果
			// 	};
			// 	// 执行滚动
			// 	window.scrollTo(scrollOptions);
				
			// }else{
			// 	window.scrollTo({
			// 		top: 0,
			// 		behavior: 'smooth' // 平滑滚动效果
			// 	});
			// }
			// AOS.init();
			// // 你也可以在这里设置全局配置
			// AOS.init({
			// 	offset: 180,
			// 	duration: 400, //持续时间
			// 	easing: 'ease-in-sine',
			// 	delay: 300,
			// 	// once:true,
			// })
			// var scrolltop = '';
			// // let mybottomdom = '';
			// // setTimeout(()=>{
			// // 	 mybottomdom = document.getElementById('mybottom').offsetTop
			// // },500)
			// window.onscroll = function(e) {
			// 	this.isscrollnum = JSON.parse(JSON.stringify(e))
			// 	let island = 1.5
			// 	//判断scroll是否向上还是向下 比较当前的值是否比之前值的大小
			// 	if (scrolltop > this.isscroll) { //向下
			// 		island = 1.5
			// 		document.getElementById('wrap_hand').className = 'wrap_hand'
			// 	} else { //上
			// 		island = -1.5
			// 		document.getElementById('wrap_hand').className = 'wrap_hand wrap_hand_animation'
			// 	}
			// 	if (scrolltop < 50) {
			// 		document.getElementById('mytitle_img').className = 'mytitle_img'
			// 	} else if (scrolltop > 857) {
			// 		document.getElementById('mytitle_img').className = ' ';
			// 	}
			// 	this.isscroll = scrolltop;
			// 	scrolltop = document.documentElement.scrollTop;
			// }
		},
		methods: {
			getdate(){
				this.$ajax({
					method:'post',
					url:this.$api.getAboutUsPage,
					data:{
						page_name:"aboutUs"
					}
				}).then(res=>{
					if(res.data.qmiscode === 1000){
						this.params = res.data
						this.jobVacancy = res.data.jobVacancy

					}else{
						this.$message.warning(res.data.qmismsg)
					}
				})
			},
			backClick(){
				this.$router.push({ path: '/', query: { id: 0 }  });
			},
            info_page(val){
                this.$router.push({
                    path: '/post',
                    query:{id:val}
                });
            },
            skipClick(val){
                if (val == 1) {
                    this.$router.push({
						path: '/', 
						query: { id: 0 } 
					});
                }
                if (val == 3) {
                    this.$router.push({
						path: '/', 
						query: { id: 3 } 
					});
                }
                if (val == 4) {
                    this.$router.push({
						path: '/', 
						query: { id: 4 } 
					});
                }
				if (val == 2) {
					this.colorFlag = val
                    this.$router.push({
						path: '/aboutUs', 
						query: { id: 2 } 
					});
					window.scrollTo({
						top: 0,
						behavior: 'smooth' // 平滑滚动效果
					});
                }
				if (val == 5) {
					this.colorFlag = val
                    this.$router.push({
						path: '/aboutUs', 
						query: { id: 5 } 
					});
					// 获取页面最底部元素
					const footerElement = document.querySelector('.jyjh_id');
					// 滚动到底部的位置
					const scrollOptions = {
						top: footerElement.offsetTop,
						behavior: 'smooth' // 使用平滑滚动效果
					};
					// 执行滚动
					window.scrollTo(scrollOptions);
					
                }
				if (val == 6) {
                    this.$router.push({
						// path: '/contactUs', 
						path: '/', 
						query: { id: 6 } 
					});
                }
            },
			menuPage(){
				console.log("菜單111111");
				this.menuFlag = !this.menuFlag
				if(this.menuFlag){
					this.stopMove()
				}else{
					this.Move()
				}
			},
			stopMove(){
				// let m = function(e){e.preventDefault();};
				// document.body.style.overflow='hidden';
				// document.addEventListener("touchmove",m,{ passive:false });//禁止页面滑动
			},
			//开启页面滚动
			Move(){
				this.menuFlag = false
				let m =function(e){e.preventDefault();};
				document.body.style.overflow='';//出现滚动条
				document.removeEventListener("touchmove",m,{ passive:true });
			},

		}
	}
</script>
<style lang='less' scoped>
* {
		box-sizing: border-box;
		-moz-box-sizing: border-box;
		-webkit-box-sizing: border-box;
		font-family: arial;
	}
	body {
		padding: 0;
		margin: 0;
		text-align: center;
		font-family: arial;
	}
	section {
		// height: 300px;
		// padding-top: 100px;
		float: left;
		width: 50%;
		position: relative;
		/*==============++++================*/
	}
	.menu {
		height: 28px;
		width: 28px;
		position: relative;
		margin: auto;
		// padding-top: 20px;
		border: 5px solid transparent;
		-moz-border-radius: 100%;
		-webkit-border-radius: 100%;
		border-radius: 100%;
		-moz-transition: 0.3s;
		-o-transition: 0.3s;
		-webkit-transition: 0.3s;
		transition: 0.3s;
		cursor: pointer;
	}
	.bar {
		height: 3px;
		width: 28px;
		display: block;
		// margin: 10px auto;
		position: relative;
		background-color: #000;
		-moz-border-radius: 10px;
		-webkit-border-radius: 10px;
		border-radius: 10px;
		-moz-transition: 0.4s;
		-o-transition: 0.4s;
		-webkit-transition: 0.4s;
		transition: 0.4s;
	}
	.model-1 .bar {
		position: absolute;
	}
	.model-1 .bar:nth-of-type(1) {
		// top: 15px;
		-moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
		-o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.3s, 0.1s;
		transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
		-moz-animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		-webkit-animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		animation: mrotr 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
	}
	.model-1 .bar:nth-of-type(2) {
		top: 10px;
		-moz-transition: 0.3s ease 0.3s;
		-o-transition: 0.3s ease 0.3s;
		-webkit-transition: 0.3s ease;
		-webkit-transition-delay: 0.3s;
		transition: 0.3s ease 0.3s;
		-moz-animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		-webkit-animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		animation: fade 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
	}
	.model-1 .bar:nth-of-type(3) {
		top: 20px;
		-moz-transition: top 0.3s ease 0.3s, -moz-transform 0.3s ease-out 0.1s;
		-o-transition: top 0.3s ease 0.3s, -o-transform 0.3s ease-out 0.1s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.3s, 0.1s;
		transition: top 0.3s ease 0.3s, transform 0.3s ease-out 0.1s;
		-moz-animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		-webkit-animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
		animation: mrotl 2s cubic-bezier(0.5, 0.2, 0.2, 1.01);
	}
	.model-1 .menu_hover .bar:nth-of-type(1) {
		top: 10px;
		-moz-transform: rotate(45deg);
		-ms-transform: rotate(45deg);
		-webkit-transform: rotate(45deg);
		transform: rotate(45deg);
		-moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
		-o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.1s, 0.5s;
		transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
	}
	.model-1 .menu_hover .bar:nth-of-type(2) {
		opacity: 0;
	}
	.model-1 .menu_hover .bar:nth-of-type(3) {
		top: 10px;
		-moz-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		transform: rotate(-45deg);
		-moz-transition: top 0.3s ease 0.1s, -moz-transform 0.3s ease-out 0.5s;
		-o-transition: top 0.3s ease 0.1s, -o-transform 0.3s ease-out 0.5s;
		-webkit-transition: top 0.3s ease, -webkit-transform 0.3s ease-out;
		-webkit-transition-delay: 0.1s, 0.5s;
		transition: top 0.3s ease 0.1s, transform 0.3s ease-out 0.5s;
	}
	.menu_class{
		font-size: 26px;
	}
    .back_hover:hover{
        color: #40bfbf;
    }
	.home_bottoom{
		width: 90%;margin: 5px auto;
		display: flex;align-items: center;
		justify-content: space-between;
		height: 45px;
		padding: 10px 0;
	}
	.home_bottoom_phone{
		width: 90%;
		margin: 5px auto;
	}
    .text_aboutUs{
        width: 50%;
        margin: 30px auto;
		padding-bottom: 100px;
    }
    .title_text{
        display: flex;
        align-items: center;
        font-weight: 600;
    }
    .title_text div{
        // margin-top: 5px;
        margin-right: 10px;
        width: 4px;
        height: 18px;
        background: #40bfbf;
    }
	.menu_div{
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.6);
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}


	body{
		color: #000;
	}
	.mydown>img {
		width: 1rem;
		cursor: pointer;
	}

	.mydown {
		position: absolute;
		bottom: -8rem;
		left: 49.5%;
	}

	.mydown img:hover {
		margin-top: .5rem;
		transition: .3s ease-in-out;
	}


	.heard {
		background-color: #f5f5f5;
	}

	.aboutUs {
		width: 100%;
		overflow: hidden;
	}



	.heard_left {
		float: left;
		padding: 10px;
	}
	.heard_left img {
	width: 17rem;
	}

	.heard_right {
		float: right;
		padding-top: 1.3rem;
	}

	.wrap_hand {
		/* display: inline-block; */
		overflow: hidden;
		position: absolute;
		width: 100%;
		/* left: 4%; */
		padding: 10px 4%;
		z-index: 100;
		background: rgba(255, 255, 255, 0.7);
		box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
	}

	.wrap_hand_animations {
		position: fixed;
		top: 0;
	}

	.wrap_hand_animation {
		.heard_left {
			padding: 0;
		}

		.wrap_hand {
			padding: 8px 4%;
		}
	}
	
	.genlist{
		text-align: center;width: 25%;margin: 1rem 0 3rem;
	}
	.fagenlist{
		display: flex;justify-content: space-evenly;
	}
	
	.wrap_hand_animation {
		position: fixed;
		top: 0;
		-webkit-animation-name: hand-out;
		-moz-animation-name: hand-out;
		-o-animation-name: hand-out;
		animation-name: hand-out;
		-webkit-animation-duration: .5s;
		-moz-animation-duration: .5s;
		-o-animation-duration: .5s;
		animation-duration: .5s;
		background: rgba(255, 255, 255, .7);
	}

	.wrap_hand_animation img {
		-webkit-transition: padding 0.3s linear;
		-moz-transition: padding 0.3s linear;
		-o-transition: padding 0.3s linear;
		transition: padding 0.3s linear;
	}

	@keyframes hand-out {
		0% {
			-webkit-transform: translateY(-110px)
		}

		100% {
			-webkit-transform: translateY(0)
		}
	}

	@-webkit-keyframes hand-out {
		0% {
			-webkit-transform: translateY(-110px)
		}

		100% {
			-webkit-transform: translateY(0)
		}
	}

	@-moz-keyframes hand-out {
		0% {
			-webkit-transform: translateY(-110px)
		}

		100% {
			-webkit-transform: translateY(0)
		}
	}

	@-o-keyframes hand-out {
		0% {
			-webkit-transform: translateY(-110px)
		}

		100% {
			-webkit-transform: translateY(0)
		}
	}

	.mytitle_img {
		-webkit-animation-name: zoom-out;
		-moz-animation-name: zoom-out;
		-o-animation-name: zoom-out;
		animation-name: zoom-out;
		-webkit-animation-duration: 10s;
		-moz-animation-duration: 10s;
		-o-animation-duration: 10s;
		animation-duration: 10s;
		width: 100vw;height: 101vh;
	}

	@-webkit-keyframes zoom-out {
		0% {
			-webkit-transform: scale(1);
		}

		100% {
			-webkit-transform: scale(1.2);
		}
	}

	@-moz-keyframes zoom-out {
		0% {
			-webkit-transform: scale(1);
		}

		100% {
			-webkit-transform: scale(1.2);
		}
	}

	@-o-keyframes zoom-out {
		0% {
			-webkit-transform: scale(1);
		}

		100% {
			-webkit-transform: scale(1.2);
		}
	}

	@keyframes zoom-out {
		0% {
			-webkit-transform: scale(1);
		}

		100% {
			-webkit-transform: scale(1.2);
		}
	}

	@media screen and(max-width:980px){
		.menu_class{
			font-size: 4.5rem;
		}
		.text_aboutUs{
			width: 90%;
		}
		.heard_left img {
			width: 63rem;
		}
		.heard_right{
			padding-top: 3.3rem;
			padding-right: 3.3rem;
		}
		.genlist img{
			width: 15rem;
		}
		.genlist{
			margin: 1rem 0 5.5rem;
		}
		/* .fagenlist{
			display: inline-block;
			justify-content: center;
			text-align: center;
		} */
		.myname{
			font-weight: 500;margin: 3.5rem 13px 0;font-size: 16px;color: #000000;
		}
		.mytitle_img {
			-webkit-animation-name: zoom-out;
			-moz-animation-name: zoom-out;
			-o-animation-name: zoom-out;
			animation-name: zoom-out;
			-webkit-animation-duration: 10s;
			-moz-animation-duration: 10s;
			-o-animation-duration: 10s;
			animation-duration: 10s;
			width: 100vw;
			height: 85rem;
		}

		.mydown{
			display: none;
		}
		.heard_left{
			padding: 0;
		}
	}
	.ipad_class{
		.heard_left img{
			width: 35rem;
		}
		.heard_right{
			padding-top: 2rem;
		}
		.menu_class{
			font-size: 26px !important;
		}
	}
</style>

